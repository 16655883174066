import { useEffect, useState } from "react";
import isMobileJs from "ismobilejs";

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(isMobileJs());

  useEffect(() => {
    setIsMobile(isMobileJs(window.navigator));
  }, [setIsMobile]);

  return isMobile;
};
